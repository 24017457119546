@import "../baseColor";

.container-galery {
    min-height: calc(100vh - 48px);

    .container-galery-header {
        display: flex;
        align-items: center;

        h4 {
            flex: 1;
        }

        p {
            color: rgba($color: #000000, $alpha: 0.6);
        }

        p:hover {
            cursor: pointer;
            color: $colorBlack;
        }

        .btn-upload-image {
            border: none;
        }

        .btn-upload-image:hover {
            color: rgba($color: #000000, $alpha: 0.6);
        }
    }

    .list-categories {
        .list-categories-header {
            background-color: orange;
            color: #000;
            font-size: 1rem;
            font-weight: bold;
        }

        .col-categories-scroll {
            height: 51vh;
        }

        .list-categories-item {
            display: flex;
            flex-direction: column;

            .category-info {
                display: flex;
                align-items: center;

                p {
                    flex: 1;
                }

                .edit-toggle {
                    background-color: transparent;
                    color: rgba($color: #000000, $alpha: 0.6);
                    border: none;
                }

                .icon-left-active-city {
                    display: none;
                }

                .icon-right-active-city {
                    color: rgba($color: #000000, $alpha: 0.3);
                }
            }

            .form-category-update {
                display: flex;
                align-items: center;
            }

        }

        .list-categories-item.active-index {
            background-color: #000000;
            color: #ffffff;
            font-weight: bold;

            .edit-toggle {
                color: #ffffff;
            }

            .icon-left-active-city {
                display: block;
                color: #ffffff;
            }

            .icon-right-active-city {
                display: none;
            }
        }

        .list-categories-item:hover {
            background-color: $colorBackground;
            cursor: pointer;
        }

        .list-categories-item.active-index:hover {
            cursor: default;
            background-color: #000000;
        }

        .list-categories-footer {
            p:hover {
                cursor: pointer;
                color: $colorBlack;
            }

            div {
                display: flex;
                align-items: center;

                input {
                    flex: 1;
                }

                button {
                    background-color: $colorOrange;
                    border: 1px solid $colorOrange;
                    color: $colorBlack;

                    .spinner-isSaving {
                        height: 16px;
                        width: 16px;
                    }
                }

                button:hover {
                    background-color: Orange;
                    border: 1px solid $colorOrange;
                }
            }
        }
    }

    .search-bar {
        .search-bar-content {
            display: flex;
            align-items: center;
            background-color: rgba($color: #000000, $alpha: 0.1);

            input {
                flex: 1;
                border: none;
                border-radius: 0px;
            }

            input::placeholder {
                color: rgba($color: #000000, $alpha: 0.3)
            }

            .btn {
                border-radius: 0px;
            }
        }
    }

    .empty-list-images {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 70vh;
    }
.card-image-dish{
    background-color: $colorBackground;
    .dish-image {}

    .btn-delete-dish {
        color: rgba($color: #000000, $alpha: 0.5);
    }

    .btn-delete-dish:hover {
        cursor: pointer;
        color: rgba($color: #000000, $alpha:1);
    }

    .dish-image:hover {
        cursor: pointer;
        opacity: 0.6;
    }
}

}

.new-modal--plan-image {
    .img-div {
        display: flex;
        align-items: center;
        justify-content: center;

        .spinner-loading {
            height: 4rem;
            width: 4rem;
        }
    }

    .parcourir-btn-file-upload {
        border: solid 1px rgba(90, 98, 104, 0.3);
        border-radius: 3px;
        background-color: #5a6268;
        text-align: center;
        color: #ffffff;
    }

    .parcourir-btn-file-upload:hover {
        cursor: pointer;
    }

    .list-categories-added {
        display: flex;
        align-items: center;

        .list-categories-added-item {
            display: flex;
            align-items: center;
            background-color: $colorBackground;
            border: solid 1px rgba($color: #000000, $alpha:0.1);
            border-radius: 15px;
        }

        .btn-add-category {
            border-radius: 15px;
            border: none;
        }

    }

    .footer-modal-new-plan-img {
        .btn {
            background-color: $colorOrange;
            border: 1px solid $colorOrange;
            color: $colorBlack;

            .spinner-isSaving {
                height: 16px;
                width: 16px;
            }
        }

        .btn:hover {
            background-color: orange;
            color: $colorWhite;
        }
    }
}

.container-import-images {
    min-height: calc(100vh - 48px);

    .dropzone-import {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        background-color: $colorWhite;
        border: 2px dashed rgba($color: #000000, $alpha: 0.1);
        height: calc(100vh - 95px);
    }

}

.row-list-dish {
    background-color: #fff;
    height: 85vh;
    border: 1px solid rgba($color: #000000, $alpha: 0.04);
}
