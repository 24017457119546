@import "../colors.scss";

.container-clients {
    .container-list-clients-header {
        height: 100px;
        background-color: $colorWhite;
        box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.08);
        padding: 8px;
        display: flex;
        flex-direction: column;

        .div-up {
            display: flex;
            margin-bottom: 8px;

            h3 {
                flex: 1;
                color: rgba($colorBlack, $alpha: 0.5);
                margin-bottom: 0px;
                font-weight: 100;
            }

            button {
                border: none;
                border-radius: 5px;
                font-size: 1rem;
                height: 32px;
                font-weight: 400;
                border: 1px solid rgba($colorBlack, $alpha: 0.1);
                padding-right: 10px;
                padding-left: 10px;
                box-shadow: 0 0 2px rgba($colorBlack, $alpha: 0.1);
                background-color: $colorWhite;
                color: rgba($colorBlack, $alpha: 0.4);
            }

            button:hover,
            button:focus {
                outline: none;
                box-shadow: 0 0 8px rgba($colorBlack, $alpha: 0.1);
                color: rgba($colorBlack, $alpha: 0.6);
            }
        }

        .div-down {
            display: flex;

            button {
                background-color: $colorGreen;
                border: none;
                color: $colorWhite;
                border-radius: 5px;
                margin-right: 8px;
                border: 1px solid rgba($colorBlack, $alpha: 0.1);
                height: 38px;
                padding-left: 8px;
                padding-right: 8px;
                box-shadow: 0 0 2px rgba($colorBlack, $alpha: 0.08);

                .left-icon {
                    margin-right: 5px;
                    font-size: 0.8rem;
                }
            }

            button:hover,
            button:focus {
                outline: none;
                box-shadow: 0 0 8px rgba($colorBlack, $alpha: 0.08);
            }

            button.download {
                background-color: $colorWhite;
                margin-left: 8px;
                width: 38px;
                margin-right: 0px;
                color: rgba($colorBlack, $alpha: 0.5);
            }

            .search-bar {
                flex: 1;

                form {
                    position: relative;

                    .search-icon {
                        position: absolute;
                        top: 12px;
                        left: 10px;
                        color: rgba($colorBlack, $alpha: 0.5);
                        z-index: 10;
                    }

                    input {
                        position: absolute;
                        border: none;
                        z-index: 11;
                        background-color: transparent;
                        // box-shadow: 0 0 2px rgba($colorBlack, $alpha: 0.08);
                        height: 38px;
                        width: 100%;
                        padding-left: 32px;
                        padding-right: 16px;
                        margin-right: 8px;
                        border-radius: 5px;
                        // border: 1px solid rgba($colorBlack, $alpha: 0.08);
                    }

                    input:focus,
                    input:hover {
                        outline: none;
                        box-shadow: 0 0 8px rgba($colorBlack, $alpha: 0.08);
                        border-color: $colorWhite;
                        border-radius: 5px;
                        // border: 1px solid rgba($colorBlack, $alpha: 0.08);
                    }
                }
            }
        }
    }

    .container-list-clients-body {
        height: calc(100vh - 152px);
        padding: 20px 30px 30px 30px;
        overflow-y: auto;

        .client-item {
            display: flex;
            align-items: center;

            .name {
                flex: 3;
                font-size: 1.2rem;
                font-weight: 300;
                img {
                    // background-color: $colorBackground;
                    border: 1px solid $colorOrange;
                    padding: 5px;
                    height: 44px;
                    width: 44px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
            }

            .phoneNumber {
                flex: 1;
                font-size: 1.2rem;
                margin-left: 20px;

                p {
                    margin-bottom: 0px;
                    font-weight: 300;
                }
            }

            .city {
                flex: 1;
                margin-left: 20px;
                font-size: 1.2rem;

                p {
                    margin-bottom: 0px;
                    font-weight: 300;
                }
                .badge{
                    background-color: $colorRed;
                    width: auto;
                    text-align: left;
                    font-size: 0.6rem;
                }
                .badge.allowed{
                    background-color: $colorGreen;
                }
                .badge.client{
                    background-color: transparent;
                }
            }
        }

        .client-item.header {

            .name {
                font-weight: bold;
                font-size: 1rem;
            }

            .phoneNumber {
                font-weight: bold;
                margin-left: 20px;
                font-size: 1rem;
            }

            .city {
                font-weight: bold;
                margin-left: 20px;
                font-size: 1rem;
            }

        }

        .client-item.header:hover {
            cursor: default;
            background-color: $colorWhite;
        }

        .client-item:hover {
            cursor: pointer;
            background-color: rgba($colorWhite, $alpha: 0.3);
        }
    }
}

.div-user-details-info{
    display: flex;
    img{
        height: 60px;
        width: 60px;
        border-radius: 50%;
        margin-right: 10px;
        padding: 8px;
        background-color: $colorBackground;
    }
    h6{
        margin-bottom: 0px;
        font-size: 1.6rem;
    }
    p{
        margin-bottom: 0px;
    }
}
.div-user-details-address{
    margin-top: 20px;
   h6{
       color: $colorGreen;
   }
   p{
       color: rgba($colorBlack, $alpha: 0.5);
       margin-left: 20px;
   }
}
.div-user-details-payment{
    h6{
        color: $colorGreen;
    }
    p{
        color: rgba($colorBlack, $alpha: 0.5);
        margin-left: 20px;
    }
}