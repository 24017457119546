@import "../baseColor";

.container-login{
    min-height: 100vh;

    .col-login-form{
        height: 100vh;
        background-color: $colorWhite;
        display: flex;
        flex-direction: column;
        .form-div{
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .form{
                width: 90%;
                input{
                    border: none;
                    background-color: $colorBackground;
                }
                .btn{
                    width: 100%;
                    color: $colorBlack;
                    background-color: $colorOrange;
                    border: 2px solid $colorOrange;
                    .spinner-login{
                        height: 16px;
                        width: 16px;
                    }
                }
                .btn:hover{
                    background-color: orange;
                }
            }
            .password-forgoted{
                color: rgba($color: #000000, $alpha: 0.3);
            }
            .password-forgoted:hover{
                color: rgba($color: #000000, $alpha: 1);
                cursor: pointer;
            }
        }
    }
    .col-login-empty-space{
        background-color: $colorBackground;
    }
}