@import '../colors.scss';

.main {
    background-color: $colorBackground;
    min-height: 100vh;

    .container-routes {
        padding-top: 52px;
        width: 100%;
        height: calc(100vh - 52px);
    }
}

@media (min-width: 992px) {
    .main {
        .container-routes {
            padding-left: 250px;
        }

        .container-routes.hide {
            padding-left: 0px;
        }
    }
}


.div-input {
    margin-bottom: 20px;

    label {
        // font-size: 1.2rem;
        font-weight: 300;
        color: rgba($colorBlack, $alpha: 0.5);
        margin-bottom: 2px;

        span {
            color: rgba($colorGreen, $alpha: 0.5);
            margin-right: 5px;
        }
    }

    input,
    select,
    textarea {
        width: 100%;
        border-radius: 8px;
        padding: 8px 20px 8px 20px;
        border: 2px solid rgba($colorBlack, $alpha: 0.05);
        font-weight: bold;
        font-size: 1.2rem;
    }

    input:focus,
    select:focus,
    textarea:focus {
        outline: none;
        box-shadow: 1px 2px 10px rgba($colorBlack, $alpha: 0.1);
        border: 2px solid $colorGreen
    }

    input::placeholder,
    select::placeholder,
    textarea::placeholder{
        color: rgba($colorBlack, $alpha: 0.1);
    }
}

.div-input-login{
    label{
        font-size: 0.8rem;
        font-weight: bold;
        color: rgba($colorBlack, $alpha: 0.6);
        margin-bottom: 0px;
    }
    input{
        width: 100%;
        // background-color: $colorBackground;
        border: 2px solid $colorBackground;
        border-radius: 8px;
        padding: 8px 20px 8px 20px;
    }
    input:focus{
        outline: none;
        box-shadow: 1px 2px 10px rgba($colorBlack, $alpha: 0.1);
        border: 2px solid $colorGreen
    }
}
.div-input-login.password{
    margin-top: 15px;
}

.toast-wrapper {
    border-radius: 8px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 1.4rem;
    font-weight: 100;
}

.btn-fresh-primary {
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    margin-right: 8px;
    height: 32px;
    font-weight: 400;
    border: 1px solid rgba($colorBlack, $alpha: 0.1);
    padding-right: 10px;
    padding-left: 10px;
    box-shadow: 0 0 2px rgba($colorBlack, $alpha: 0.1);
    background-color: $colorGreen;
    color: $colorWhite;
    box-shadow: 0 0 8px rgba($colorBlack, $alpha: 0.1);
    .spinner {
        height: 16px;
        width: 16px;
    }
}

.btn-fresh-muted {
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    margin-right: 8px;
    height: 32px;
    font-weight: 400;
    border: 1px solid rgba($colorBlack, $alpha: 0.1);
    padding-right: 10px;
    padding-left: 10px;
    box-shadow: 0 0 2px rgba($colorBlack, $alpha: 0.1);
    background-color: $colorWhite;
    color: rgba($colorBlack, $alpha: 0.5);

    .spinner {
        height: 16px;
        width: 16px;
    }
}

.btn-fresh-primary:hover,
.btn-fresh-primary:focus,
.btn-fresh-muted:hover,
.btn-fresh-muted:focus {
    outline: none;  
}

.btn-fresh-login{
    background-color: $colorGreen;
    color: $colorWhite;
    font-weight: 100;
    border: none;
    border-radius: 20px;
    padding: 8px 30px 8px 30px;
    font-size: 1.4rem;
}
.btn-fresh-login:hover,
.btn-fresh-login:focus{
    outline: none;
    box-shadow: 1px 2px 16px rgba($colorBlack, $alpha: 0.1);
}


.search-bar-activities{
    width: 100%;
    display: flex;
    form{
        flex: 1;
        position: relative;
        .search-icon{
            position: absolute;
            top: 12px;
            left: 10px;
            color: rgba($colorBlack, $alpha: 0.5);
            z-index: 10;
        }
        input{
            position: absolute;
            border: none;
            z-index: 11;
            background-color: transparent;
            height: 38px;
            width: 100%;
            padding-left: 32px;
            padding-right: 16px;
            margin-right: 8px;
            border-radius: 5px;
        }
        input::placeholder{
            font-size: 0.8rem;
        }
        input:hover{
            box-shadow: 0 0 2px rgba($colorBlack, $alpha: 0.08);
            border: 1px solid rgba($colorBlack, $alpha: 0.08);
        }
        input:focus{
            outline: none;
            box-shadow: 0 0 8px rgba($colorBlack, $alpha: 0.08);
            border-color: $colorWhite;
            border-radius: 5px;
            border: 1px solid rgba($colorBlack, $alpha: 0.08);
        }
    }
}

.container-biker-activities{
    .header{
        display: flex;
        .btn-fresh-primary{
            display: flex;
            align-items: center;
            height: auto;
            .icon{
                margin-left: 5px;
            }
        }
        .btn-fresh-primary:hover{
            background-color: $colorGreen;
            border: none;
        }
        .btn-fresh-primary:focus{
            background-color: $colorGreen;
            border: none;
            outline: none;
        }
        .drop-menu{
            border: none;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.08);
            padding: 15px;
            .item{
                padding: 5px 5px 5px 5px;
                border-radius: 5px;
            }
            .item:focus{
                outline: none;
                background-color: $colorBackground;
                color: inherit;
            }
        }
        .drop-menu:focus{
            outline: none;
        }
        .search-bar-activities{
            flex: 1;
        }
    }
    .row.list-activities{
        margin-top: 20px;
    }
    .order-item {
        display: flex;
        margin-bottom: 15px;
        border-radius: 8px;
        border: none;
        background-color: transparent;
        box-shadow: 0 0 5px rgba($colorBlack, $alpha: 0.08);
        .order-info {
            display: flex;
            flex: 1;
            align-items: center;

            .div-icon {
                background-color: $colorWhite;
                color: $colorOrange;
                box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.03);
                // color: red;
                height: 52px;
                width: 52px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                font-size: 1.4rem;
                margin-right: 8px;
            }

            h6 {
                margin-bottom: 0px;
                
                .badge {
                    background-color: $colorRed;
                    font-size: 0.6rem;
                }
                .badge.delivered{
                    background-color:$colorGreen;
                }
            }

            p {
                margin-top: 3px;
                margin-bottom: 0px;
                font-size: 0.6rem;
                font-weight: 300;
            }
        }

        .order-user {
            display: flex;
            align-items: center;
            flex: 1;
            img {
                height: 32px;
                width: 32px;
                border-radius: 50%;
                margin-right: 8px;
            }
            div{
            h6 {
                margin-bottom: 0px;
                font-weight: 300;
            }

            p {
                margin-bottom: 0px;
                color: $colorBlack;
                font-weight: 200;
                font-size: 0.7rem;
            }
        }

        }

        .order-timing {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 52px;
            height: 52px;
            border-radius: 50%;
            background-color: $colorBackground;
            color: $colorOrange;
            font-weight: bold;

            .order-time-unit {
                font-size: 0.7rem;
                color: $colorBlack;
            }
        }
    }

    .order-item:hover {
        background-color:$colorWhite;
        cursor: pointer;
        box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.06);
        .div-icon{
            background-color: $colorBackground;
            box-shadow: none;
        }
    }
}

.div-client-info-delivery{
    display:flex;
    h6{
        margin-bottom: 0px;
        font-size: 1.4rem;
        font-weight: 400;
    }
    p{
        margin-bottom: 0px;
        color: rgba($colorBlack, $alpha: 0.5);
    }
    img{
        height: 48px;
        width: 48px;
        padding: 5px;
        border-radius: 50%;
        background-color: $colorBackground;
        margin-right: 10px;
    }
    
}
