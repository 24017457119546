@import "../colors.scss";


.card-with-internet{
    // background-color: transparent;
    // color: $colorGreen;
    height: 100px;
    padding: 8px;
    // border-bottom: 1px solid rgba($colorBlack, $alpha: 0.08);
    h4{
        margin-bottom: 0px;
        font-weight: 300;
        color: $colorGreen;
        font-size: 1rem;
    }
    .card-with-internet-body{
        display: flex;
        align-items: center;
        margin-top: 8px;
        .store-icon{
            height: 48px;
            width: 48px;
            font-size: 32px;
            background-color: $colorWhite;
            // border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba($colorBlack, $alpha: 0.5);
            margin-right: 8px;
            // box-shadow: 0 0 10px rgba($colorOrange, $alpha: 0.1);
        }
        .message{
            flex: 1;
            p{
                font-size: 0.7rem;
                margin-bottom: 0px;
                color: rgba($colorBlack, $alpha: 0.5);
            }
        }
    }
    
}

.card-no-internet-connexion{
// background-color: $colorGreen;
height: 100px;
padding: 15px 8px 15px 8px;
.card-no-internet-title{
    font-size: 1rem;
    font-weight: 200;
    color: $colorRed;
}
.card-no-internet-body{
display: flex;
align-items: center;
position: relative;
.icon{
    font-size: 32px;
    color: rgba($colorBlack, $alpha: 0.5);
    
}
.icon.slash{
    position: absolute;
    left: 1px;
    color: rgba($colorRed, $alpha: 0.5);
    top:3px;
}
.card-no-internet-content{
    font-size: 0.7rem;
    margin-left: 8px;
    color: rgba($colorBlack, $alpha: 0.5);
}
}
}