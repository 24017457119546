@import "../colors.scss";

.home-container {
    
}

.home-content {
    .nav-orders {
        padding-top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $colorWhite;
        border: none;
        box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.08);
        .link-tab {
            font-size: 1.4rem;
            font-weight: 300;
            color: rgba($colorBlack, $alpha: 0.5);
            border-bottom: 2px solid $colorWhite;
            .icon {
                margin-left: 20px;
                font-size: 0.8rem;
            }
        }
        .link-tab.active {
            font-weight: 500;
            background-color: rgba($colorGreen, $alpha: 0.2);
            border: none;
            border-radius: 8px 8px 0px 0px;
            color: $colorGreen;
            border-bottom: 2px solid $colorGreen;
        }

        .link-tab:hover {
            cursor: pointer;
            border: none;
            background-color: rgba($colorGreen, $alpha: 0.08);
            border-radius: 8px 8px 0px 0px;
        }
        .link-tab.active:hover{
            cursor: default;
            border-bottom: 2px solid $colorGreen;
        }

        .empty-space {
            flex: 1;
        }

        button {
            background-color: $colorWhite;
            color: rgba($colorBlack, $alpha: 0.3);
            border: 2px solid rgba($colorBlack, $alpha: 0.1);
            border-radius: 5px;
        }

        button:focus,
        button:hover {
            outline: none;
            box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.08);
            color: rgba($colorBlack, $alpha: 0.8);
        }
    }
.container-orders{
     height: calc(100vh - 112px);
     overflow-y: auto;
     overflow-x: hidden;
    .list-home-orders {
        padding: 15px 8px 15px 8px;
        .order-item {
            display: flex;
            margin-bottom: 15px;
            border-radius: 8px;
            border: none;
            box-shadow: 0 0 5px rgba($colorBlack, $alpha: 0.08);
            .order-info {
                display: flex;
                flex: 1;
                align-items: center;

                .div-icon {
                    background-color: $colorBackground;
                    color: $colorOrange;
                    // color: red;
                    height: 52px;
                    width: 52px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    font-size: 1.4rem;
                    margin-right: 8px;
                }

                h6 {
                    margin-bottom: 0px;

                    .badge {
                        background-color: $colorRed;
                        font-size: 0.5rem;
                    }
                    .badge.paid{
                        background-color:$colorGreen;
                    }
                }

                p {
                    margin-top: 5px;
                    margin-bottom: 0px;
                    font-size: 0.6rem;
                    font-weight: 300;
                }
            }

            .order-user {
                display: flex;
                align-items: center;
                flex: 1;
                img {
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                    margin-right: 8px;
                }
                div{
                h6 {
                    margin-bottom: 0px;
                    font-weight: 300;
                }

                p {
                    margin-bottom: 0px;
                    color: $colorBlack;
                    font-weight: 200;
                    font-size: 0.7rem;
                }
            }

            }

            .order-timing {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 52px;
                height: 52px;
                border-radius: 50%;
                background-color: $colorBackground;
                color: $colorOrange;
                font-weight: bold;

                .order-time-unit {
                    font-size: 0.7rem;
                    color: $colorBlack;
                }
            }
        }

        .order-item:hover {
            background-color: rgba($colorGreen, $alpha: 0.1);
            cursor: pointer;
            box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.06);
        }
    }}

    .float-bikers{
        position: fixed;
        background-color: $colorWhite;
        min-height: 100vh;
        width: 100px;
        right: 0px;
        top: 52px;
        bottom: 0px;
        border-left: 1px solid rgba($colorBlack, $alpha: 0.08);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding:8px;
        .empty-bikers-list{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: rgba($colorBlack, $alpha: 0.5);
            margin-top: 20px;
            p{
                margin-bottom: 0px;
                font-size: 0.6rem;
            }
        }
        .card-biker{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            padding:3px;
            img{
                height: 48px;
                width:48px;
                border-radius: 50%;
                background-color: $colorBackground;
            }
            p{
                font-size: 0.3rem;
                margin-bottom: 0px;
            }
        }
        .card-biker:hover{
            box-shadow: 0 0 5px rgba($colorBlack, $alpha: 0.08);
            border-radius: 5px;
            
        }
    }
    .btn-float-bikers{
        position: fixed;
        right: 15px;
        bottom: 15px;
        background-color: $colorOrange;
        height: 48px;
        width: 48px;
        border-radius: 50%;
        box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.2);
        border:2px solid $colorWhite;
        color:$colorWhite
    }
    .btn-float-bikers:focus,
    .btn-float-bikers:hover{
        outline:none;
        font-size: 1.2rem;
    }
}

.card-ready-biker{
    box-shadow: 0 0 5px rgba($colorBlack, $alpha: 0.08);
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    
    img{
        height: 64px;
        width: 64px;
        border-radius: 50%;
    }
    h6{
        margin-bottom: 0px;
        margin-top:5px;
        text-align: center;
        font-size: 0.6rem;
    }
    p{
        margin-bottom: 0px;
        text-align: center;
        font-size: 0.5rem;
    }
    .spinner{
        height: 64px;
        width: 64px;
        color: rgba($colorBlack, $alpha: 0.3);
    }
}

.card-ready-biker:hover{
    cursor: pointer;
    box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.08);
    background-color: $colorBackground;
}