@import '../baseColor';

.container-restaurants{
    min-height: calc(100vh - 48px);
    .container-header-resto{
        display: flex;
        align-items: center;
        h4{
            flex: 1;
        }
        p{
            color: rgba($color: #000000, $alpha: 0.6);
        }
        p:hover{
            cursor: pointer;
            color: $colorBlack;
        }
    }
    .search-bar{
        .search-bar-content{
            display: flex;
            align-items: center;
            background-color: rgba($color: #000000, $alpha: 0.1);
            input{
                flex: 1;
                border: none;
                border-radius: 0px;
            }
            input::placeholder{
                color:rgba($color: #000000, $alpha: 0.3)
            }
            .btn{
                border-radius: 0px;
            }
        }
    }
    .empty-list-restaurant{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
.modal-new-resto{
    .btn{
        background-color: $colorOrange;
        border: 1px solid $colorOrange;
        color: $colorBlack;
        .spinner-isSaving{
            height: 16px;
            width: 16px;
        }
    }
    .btn:hover{
        background-color: orange;
        color: $colorWhite;
    }
    .progress-upload{
        height: 2px;
    }
    .spinner-upload-div{
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .parcourir-btn-file-upload{
        border:solid 1px rgba(90, 98, 104, 0.3);
        border-radius:3px;
        background-color:#5a6268;
        text-align:center;
        color:#ffffff;
    }
    .parcourir-btn-file-upload:hover{
        cursor: pointer;
    }
    .logo-img{
        max-width: 100%;
    }
    .information-payement-div{
        background-color: rgba($color: #000000, $alpha:0.1);
        border-radius: 10px;
    }
}
.list-cities{
    .list-cities-header{
        background-color: rgba($color: #000000, $alpha:0.1);
        font-size: 1rem;
        font-weight: bold;
    }
    .list-cities-item{
        display: flex;
        flex-direction: column;
        .city-info{
            display: flex;
            align-items: center;
            p{
                flex: 1;
            }
            .edit-toggle{
                background-color: transparent;
                color: rgba($color: #000000, $alpha: 0.6);
                border: none;
            }
            .icon-left-active-city{
                display: none;
            }
            .icon-right-active-city{
                color: rgba($color: #000000, $alpha: 0.3);
            }
        }
        .form-city-update{
            display: flex;
            align-items: center;
        }
    }
    .list-cities-item.active-index{
        background-color: $colorBackground;
        .icon-left-active-city{
            display: block;
            color: rgba($color: #000000, $alpha: 1);
        }
        .icon-right-active-city{
            display: none;
        }
    }
    .list-cities-item:hover{
        background-color: $colorBackground;
    }
    .list-cities-footer{
        p:hover{
            cursor: pointer;
            color: $colorBlack;
        }
        div{
            display: flex;
            align-items: center;
            input{
                flex: 1;
            }
            button{
                background-color: $colorOrange;
                border:1px solid $colorOrange;
                color: $colorBlack;
            }
            button:hover{
                background-color: Orange;
                border:1px solid $colorOrange;
            }
        }
    }
}

.list-resto{
        .list-resto-item{
            display: flex;
            .resto-info{
                flex:1;
                .resto-name{
                    font-size: 0.9rem;
                }
                .resto-city{
                    font-size: 0.6rem;
                }
            }
            .toggle-edit{
                background-color: transparent;
                color: rgba(0, 0, 0, 0.5);
                border: none;
            }
        }
        .list-resto-item:hover{
            background-color: $colorBackground;
        }
    
}

.edit-restaurant-form{
    .edit-header{
        border-bottom: 1px solid rgba($color: #000000, $alpha:0.1);
    }
    .parcourir-btn-file-upload:hover{
        cursor: pointer;
    }
    .parcourir-btn-file-upload{
        text-align: center;
        font-weight: bold;
        color: $colorWhite;
        border-radius: 2px;
        background-color: rgba($color: #000000, $alpha: 0.5);
    }
    .progress-upload{
        height: 4px;
    }
    .information-payement-div{
        background-color: rgba($color: #000000, $alpha:0.1);
        border-radius: 10px;
    }
}

.no-selected-resto{
    min-height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    .icon{
        font-size: 3rem;
        margin-bottom: 10px;
        color: rgba($colorBlack, $alpha: 0.3);
    }
    h6{
        font-weight: 200;
        font-size: 1.2rem;
    }
}

.card-store{
    border-radius: 5px;
}
.card-store:hover{
    cursor: pointer;
}

