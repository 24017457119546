@import "../baseColor";

.container-users{
    min-height: calc(100vh - 48px);
    .container-header-user{
        display: flex;
        align-items: center;
        h4{
            flex: 1;
        }
        p{
            color: rgba($color: #000000, $alpha: 0.6);
        }
        p:hover{
            cursor: pointer;
            color: $colorBlack;
        }
    }
    .container-dashboard-user{
        .user-value-number{
            font-size: 1.2rem;
        }
    }
}

.footer-form-new-admin{
    .btn{
        background-color: $colorOrange;
        color: $colorBlack;
        border: solid 2px $colorOrange;
        .spinner-isSaving{
            height: 16px;
            width: 16px;
        }
    }
    .btn:hover{
        background-color: orange;
    }
}

.list-admin-users-header{
    background-color: rgba($color: #000000, $alpha: 0.1);
}
.list-admin-users-item{
    display: flex;
    align-items: center;
    p{
        font-size: 0.7rem;
    }
}