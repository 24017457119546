@import '../baseColor';

.container-settings {
    min-height: calc(100vh - 50px);
    padding: 30px;
    .setting-item.active-menu{
        color:$colorOrange;
        font-weight: bold;
    }
    .setting-item:hover{
        cursor: pointer;
        color: $colorOrange;
    }

}

.units-container {
    .units-container-header {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        h4 {
            flex: 1;
            margin-bottom: 0px;
            font-weight: 300;
        }

        button {
            border: none;
            background-color: $colorOrange;
            color: $colorWhite;
            border-radius: 5px;
            padding: 5px 8px 5px 8px;
            height: fit-content;
            font-weight: bold;
        }

        button:hover,
        button:focus {
            outline: none;
            box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.08);
        }
    }

    .list-units {
        .unit-item {
            display: flex;

            .unit,
            .category {
                flex: 1;
            }
            img{
                height: 24px;
                widows: 24px;
                margin-right: 10px;
            }
        }

        .unit-item.header {
            font-weight: bold;
        }

        .unit-item:nth-child(even) {
            background-color: transparent;
        }
        .unit-item:hover{
            cursor: pointer;
            background-color: rgba($colorBlack, $alpha: 0.1);
        }

        
    }
}

.loading-data-indicator {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-weight: 300;
        font-size: 1rem;
    }
}

.empty-list-data {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-weight: 300;
        font-size: 1rem;
    }
}

.modal-new-unit {
    .modal-new-unit-body {
        .div-input {
            margin-bottom: 20px;

            input,
            select {
                padding: 5px 8px 5px 8px;
                width: 100%;
                border-radius: 5px;
                border: 2px solid rgba($color: #000000, $alpha: 0.1);
            }

            input:focus,
            select:focus {
                outline: none;
                border-color: $colorOrange;
            }
        }
    }
    .modal-new-unit-footer {
        button {
            background-color: $colorWhite;
            color: rgba($color: #000000, $alpha: 0.5);
            padding: 5px 8px 5px 8px;
            border-radius: 5px;
            border: 2px solid rgba($color: #000000, $alpha: 0.1);
        }

        button:hover,
        button:focus {
            outline: none;
            box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.08);
        }

        button.btn-save {
            background-color: $colorOrange;
            color: $colorWhite;

            .spinner {
                height: 16px;
                width: 16px;
            }
        }
    }
}

.div-image-category{
    label{
        // font-size: 1.2rem;
        font-weight: 300;
        color: rgba($colorBlack, $alpha: 0.5);
        span{
            margin-right: 5px;
            color: rgba($colorGreen, $alpha: 0.5);
        }
    }
    .dropzone-image{
        width: 100%;
        height: 250px;
        border: 2px solid rgba($colorBlack, $alpha: 0.05);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn-upload{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            p{
                font-size: 3rem;
                margin-bottom: 0px;
                color: rgba($colorBlack, $alpha: 0.1);
            }
            h6{
                margin-bottom: 0px;
                color: rgba($colorBlack, $alpha: 0.3);
            }
        }
    }
    .dropzone-image:hover{
        cursor: pointer;
        box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.05);
    }
    .dropzone-image:focus,
    .dropzone-image.required{
        outline: none;
        border: 2px solid $colorGreen;
        box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.08);
    }
}

.container-payments-settings{
    .payments-header{
        display: flex;
        flex-direction: row;
        align-items: center;
        h4{
            flex: 1;
            margin-bottom: 0px;
            font-weight: 300;
        }
        button {
            border: none;
            background-color: $colorOrange;
            color: $colorWhite;
            border-radius: 5px;
            padding: 5px 8px 5px 8px;
            height: fit-content;
            font-weight: 400;
        }

        button:hover,
        button:focus {
            outline: none;
            box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.08);
        }
    }  
}

.div-input {
    margin-bottom: 20px;
    input,
    select {
        padding: 5px 8px 5px 8px;
        width: 100%;
        border-radius: 5px;
        border: 2px solid rgba($color: #000000, $alpha: 0.1);
    }

    input:focus,
    select:focus {
        outline: none;
        border-color: $colorOrange;
    }
}

.image-telecom:hover{
cursor: pointer;
}
.div-network-logo{
    .dropzone-image{
        width: 100%;
        height: 223px;
        border: 2px solid rgba($colorBlack, $alpha: 0.05);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn-upload{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            p{
                font-size: 3rem;
                margin-bottom: 0px;
                color: rgba($colorBlack, $alpha: 0.1);
            }
            h6{
                margin-bottom: 0px;
                color: rgba($colorBlack, $alpha: 0.3);
            }
        }
    }
    .dropzone-image:hover{
        cursor: pointer;
        box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.05);
    }
    .dropzone-image:focus,
    .dropzone-image.required{
        outline: none;
        border: 2px solid $colorGreen;
        box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.08);
    }
    .dropzone-image.no-image{
        border: 2px solid $colorOrange;
        box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.08);
    }
}

.div-recharge-steps{
    border: 2px solid rgba($colorBlack, $alpha: 0.08);
    border-radius: 5px;
    padding: 5px;
    .step-item{
        display: flex;
        align-items: center;
        font-size: 0.7rem;
        border-bottom: none;
        .verb{
            flex: 1;
            font-weight: bold;
            margin-bottom: 0px;
        }
        .action-message{
            flex: 2;
            margin-bottom: 0px;
        }
    }
    .step-item:nth-child(even){
        background-color: #f9f9f9;
    }

    .form-add-step{
        display: flex;
        align-items: flex-start;
        background-color: #f9f9f9;
        padding: 5px;
        border-radius: 5px;
        input{
            flex: 1;
            margin-right: 5px;
            border: 1px solid rgba($colorBlack, $alpha: 0.1);
            border-radius: 5px;
        }
        textarea{
            flex: 2;
            margin-right: 5px;
            border: 1px solid rgba($colorBlack, $alpha: 0.1);
            border-radius: 5px;
        }
        input:hover,
        textarea:hover,
        input:focus,
        textarea:focus{
            outline: none;
            border-color: $colorOrange;
        }
        .btn-add{
            margin-right: 5px;
        }
        
    }
    .btn-cancel{
        background-color: transparent;
        border: none;
    }
    .btn-cancel:hover,
    .btn-cancel:focus{
        outline: none;
    }
    .btn-add{
        border: none;
        color:$colorWhite;
        font-weight: 400;
        font-size: 0.8rem;
        border-radius: 3px;
        background-color: $colorOrange;
    }
}

.btn-save{
    background-color: $colorOrange;
    border: none;
    border-radius: 5px;
    padding: 5px 15px;
    color:#3d3d3d;
}

.btn-save:hover,
.btn-save:focus{
    outline: none;
    color: $colorBlack;
}

.btn-cancel-new{
    background-color: transparent;
    border: none;
    border-radius: 5px;
    color: #3d3d3d;
}

.btn-cancel-new:hover,
.btn-cancel-new:focus{
    outline: none;
    color: $colorBlack;
}