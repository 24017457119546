@import '../baseColor';

.container-livreurs{
    min-height: calc(100vh - 48px);
    background-color: $colorBackground;
    .container-livreurs-header{
        display: flex;
        align-items: center;
        h4{
            flex:1;
        }
        p{
            color: rgba($color: #000000, $alpha: 0.6);
        }
        p:hover{
            color:rgba($color: #000000, $alpha: 1);
        }
    }

    .search-bar{
        .search-bar-content{
            display: flex;
            align-items: center;
            background-color: rgba($color: #000000, $alpha: 0.1);
            input{
                flex: 1;
                border: none;
                border-radius: 0px;
            }
            input::placeholder{
                color:rgba($color: #000000, $alpha: 0.3)
            }
            .btn{
                border-radius: 0px;
            }
        }
    }
}

.new-modal-livreur-form{
    .col-avatar-livreur{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .spinner-loading{
            height: 4rem;
            width: 4rem;
        }
    }
    .parcourir-btn-file-upload{
        border:solid 1px rgba(90, 98, 104, 0.3);
        border-radius:3px;
        background-color:#5a6268;
        text-align:center;
        color:#ffffff;
    }
    .parcourir-btn-file-upload:hover{
        cursor: pointer;
    }
    .footer-modal-livreur{
        .btn{
            background-color: $colorOrange;
            border: 1px solid $colorOrange;
            color: $colorBlack;
            .spinner-isSaving{
                height: 16px;
                width: 16px;
            }
        }
        .btn:hover{
            background-color: orange;
            color: $colorWhite;
        }
    }
}

.list-livreur{

    .list-livreur-item{
        display: flex;
        align-items: center;
        .info-1{
            flex: 2;
            p{
                font-size: 0.7rem;
            }
            h6{
                font-size: 1.2rem;
            }
        }
        .info-2{
            flex: 1;
            p{
                font-size: 0.7rem;
            }
            h6{
                font-size: 0.8rem;
            }
        }
        .toggle-edit{
            background-color: transparent;
            color: rgba($color: #000000, $alpha: 0.6);
            border: none;
        }
    }
    .list-livreur-item:hover{
        background-color: $colorBackground;
    }
}


.list-cities-livreurs{
.list-city-item-header{
    background-color: rgba($color: #000000, $alpha:0.1);
    font-size: 1rem;
    font-weight: bold;
}
.list-cities-item{
    display: flex;
    align-items: center;
    p{
        flex: 1;
    }
    .icon-left-active-city{
        display: none;
    }
    .icon-right-active-city{
        color: rgba($color: #000000, $alpha: 0.3);
    }

}
.list-cities-item:hover{
    background-color: $colorBackground;
}
.list-cities-item.active-index{
    background-color: $colorBackground;
    .icon-left-active-city{
        display: block;
        color: rgba($color: #000000, $alpha: 1);
    }
    .icon-right-active-city{
        display: none;
    }
}
}

.empty-list-livreur{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.edit-rider-form{
    background-color: rgba($color: #000000, $alpha:0.1);
    .header-edit{
        border-bottom: 1px solid rgba($color: #000000, $alpha:0.1);
    }
    .parcourir-btn-file-upload{
        text-align: center;
        font-weight: bold;
        color: $colorWhite;
        border-radius: 2px;
        background-color: rgba($color: #000000, $alpha: 0.5);
    }
}
