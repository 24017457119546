
.burger-btn{
    width: 32px;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    .burger-btn-bar{
        height: 3px;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.burger-btn:hover,
.burger-btn:active{
    cursor: pointer;
    .burger-btn-bar{
        background-color: rgba(0, 0, 0, 0.7);
    }
}

@media (min-width: 992px){
    .burger-btn{
        display: none;
    } 
}
