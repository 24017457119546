.backdrop{
    position: fixed;
    z-index: 200;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}
@media (min-width: 992px){
    .backdrop{
        display: none;
    } 
}