@import "../colors.scss";

.container-helps{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    min-height: calc(100vh - 52px);
    .help-icon{
        font-size: 5rem;
        color: rgba($colorOrange, $alpha: 0.4);
    }
    p{
        margin-top: 10px;
        margin-bottom: 0px;
        font-size: 1.5rem;
        font-weight: 300;
        color: rgba($colorBlack, $alpha: 0.5);
        text-align: center;
    }
}