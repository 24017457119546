@import "../colors.scss";

.nav-settings{
    background-color: $colorWhite;
    display: flex;
    justify-content: center;
    padding-top: 8px;
    .link-setting{
        font-weight: 100;
        color: rgba($colorBlack, $alpha: 0.5);
        .icon{
            font-size: 0.8rem;
        }
    }
    .link-setting:hover{
        cursor: pointer;
    }
    .link-setting.active{
        background-color: rgba($colorGreen, $alpha: 0.2);
        border-radius: 8px 8px 0px 0px;
        border-bottom: 2px solid $colorGreen;
        color: $colorGreen;
    }
    .link-setting.active:hover{
        cursor: default;
    }
}

// -------- Times settings --------- //
.container-settings-category {
    padding: 60px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .settings-header {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        h3 {
            flex: 1;
            color: rgba($colorBlack, $alpha: 0.5);
            font-weight: 300;
        }

        button {
            height: fit-content;
        }
    }

    .edit-dlps-number-info {
        color: $colorOrange;
        font-size: 0.6rem;
    }

    .form-container-content {
        background-color: $colorWhite;
        width: 400px;
        padding: 30px;
        border-radius: 30px;
        box-shadow: 0 0 20px rgba($colorBlack, $alpha: 0.08);

        h3 {
            color: $colorGreen;
            font-weight: 300;
            margin-bottom: 20px;
        }

        .view-balance-link {
            color: $colorGreen;
            font-size: 1rem;
            text-align: right;
        }

        .view-balance-link:hover {
            cursor: pointer;
            text-decoration: underline;
        }

        .div-delivery-cost {
            display: flex;
            align-items: center;

            .label-currency {
                margin-left: 10px;
                font-size: 1.6rem;
                font-weight: 100;
            }
        }
    }

    .form-container-content-store {
        background-color: $colorWhite;
        padding: 30px;
        border-radius: 30px;
        box-shadow: 0 0 20px rgba($colorBlack, $alpha: 0.08);

        h3 {
            font-weight: 300;
            color: $colorGreen;
            margin-bottom: 20px;
        }
    }
}


// ------------- security settings ---------//
.container-settings-security{
    padding: 60px 20px 20px 20px;
}


// ----------- no-feature css ------------ //
.container-no-feature{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 200px);
    .launching-image{
        height: 100px;
        width: 100px;
    }
}