@import "../colors.scss";

.container-list-orders {
    .container-list-orders-header {
        height: 100px;
        background-color: $colorWhite;
        box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.08);
        padding: 8px 8px 8px 8px;
        display: flex;
        flex-direction: column;

        .div-up {
            display: flex;
            margin-bottom: 8px;

            h3 {
                flex: 1;
                color: rgba($colorBlack, $alpha: 0.5);
                margin-bottom: 0px;
                font-weight: 300;
            }

            button {
                border: none;
                border-radius: 5px;
                font-size: 1rem;
                height: 32px;
                font-weight: 400;
                border: 1px solid rgba($colorBlack, $alpha: 0.1);
                padding-right: 10px;
                padding-left: 10px;
                box-shadow: 0 0 2px rgba($colorBlack, $alpha: 0.1);
                background-color: $colorWhite;
                color: rgba($colorBlack, $alpha: 0.4);
            }

            button:hover,
            button:focus {
                outline: none;
                box-shadow: 0 0 8px rgba($colorBlack, $alpha: 0.1);
                color: rgba($colorBlack, $alpha: 0.6);
            }
        }

        .div-down {
            display: flex;

            button {
                background-color: $colorGreen;
                border: none;
                color: $colorWhite;
                border-radius: 5px;
                margin-right: 8px;
                border: 1px solid rgba($colorBlack, $alpha: 0.1);
                height: 38px;
                padding-left: 8px;
                padding-right: 8px;
                box-shadow: 0 0 2px rgba($colorBlack, $alpha: 0.08);

                .left-icon {
                    margin-right: 5px;
                    font-size: 0.8rem;
                }
            }

            button:hover,
            button:focus {
                outline: none;
                box-shadow: 0 0 8px rgba($colorBlack, $alpha: 0.08);
            }

            button.download {
                background-color: $colorWhite;
                margin-left: 8px;
                width: 38px;
                margin-right: 0px;
                color: rgba($colorBlack, $alpha: 0.5);
            }

            .search-bar {
                flex: 1;

                form {
                    position: relative;

                    .search-icon {
                        position: absolute;
                        top: 12px;
                        left: 10px;
                        color: rgba($colorBlack, $alpha: 0.5);
                        z-index: 10;
                    }

                    input {
                        position: absolute;
                        border: none;
                        z-index: 11;
                        background-color: transparent;
                        // box-shadow: 0 0 2px rgba($colorBlack, $alpha: 0.08);
                        height: 38px;
                        width: 100%;
                        padding-left: 32px;
                        padding-right: 16px;
                        margin-right: 8px;
                        border-radius: 5px;
                        // border: 1px solid rgba($colorBlack, $alpha: 0.08);
                    }

                    input:focus,
                    input:hover {
                        outline: none;
                        box-shadow: 0 0 8px rgba($colorBlack, $alpha: 0.08);
                        border-color: $colorWhite;
                        border-radius: 5px;
                        // border: 1px solid rgba($colorBlack, $alpha: 0.08);
                    }
                }
            }
        }
    }

    .container-list-orders-body {
        height: calc(100vh - 152px);
        padding: 20px 30px 30px 30px;
        overflow-y: auto;

        .order-item.header {
            font-weight: bold;
            font-size: 1rem;
        }

        .order-item.header:hover {
            background-color: $colorWhite;
            cursor: default;
        }

        .order-item {
            display: flex;
            align-items: center;
            border-color: rgba($colorBlack, $alpha: 0.05);

            h6 {
                margin-bottom: 0px;
                font-size: 1.2rem;
                font-weight: 300;
            }

            p {
                margin-bottom: 0px;
                font-size: 0.6rem;
            }

            .div-order {
                flex: 2;
            }

            .div-client {
                flex: 5;
                display: flex;
                align-items: center;

                img {
                    height: 44px;
                    width: 44px;
                    border-radius: 50%;
                    background-color: $colorBackground;
                    margin-right: 8px;
                }
            }

            .div-status {
                flex: 1;

                .badge {
                    background-color: $colorRed;
                    width: 50px;
                    text-align: left;
                }

                .badge.delivered {
                    background-color: $colorGreen;
                }
            }
        }

        .order-item:hover {
            cursor: pointer;
            background-color: rgba($colorWhite, $alpha: 0.3);
        }

        .order-item-history{
            display: flex;
            margin-bottom: 15px;
            border-radius: 8px;
            border: none;
            box-shadow: 0 0 5px rgba($colorBlack, $alpha: 0.08);
            background-color: transparent;
            padding: 10px;
            .div-icon {
                background-color: $colorWhite;
                color: $colorOrange;
                // color: red;
                height: 42px;
                width: 42px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                font-size: 1.2rem;
                margin-right: 8px;
            }
            h6{
                margin-bottom: 0px;
                .badge {
                    background-color: $colorRed;
                    text-align: left;
                    font-size: 0.6rem;
                }

                .badge.delivered {
                    background-color: $colorGreen;
                }
            }
            p{
                margin-bottom: 0px;
                font-size: 0.6rem;
            }
        }
        .order-item-history:hover{
            background-color: $colorWhite;
            cursor: pointer;
            box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.06);
            .div-icon{
                background-color: $colorBackground
            }
        }
    }
}

.modal-backdrop {
    background-color: rgba($colorBlack, $alpha: 0.8)
}

.modal-content {
    border-radius: 30px;
    padding: 10px 20px 10px 20px;
    border: none;
    box-shadow: 0 0 10px rgba($colorGreen, $alpha: 0.08);

    .modal-header {
        display: flex;
        border: none;

        h5 {
            flex: 1;
            margin-bottom: 0px;
            font-weight: 300;
            .badge {
                background-color: $colorRed;
                text-align: left;
                font-size: 0.7rem;
            }

            .badge.delivered {
                background-color: $colorGreen;
            }
            .badge-payement {
                background-color: $colorRed;
                font-size: 0.7rem;
            }
            .badge-payement.paid{
                background-color:$colorGreen;
            }
        }

        button {
            background-color: transparent;
            border: none;
            color: rgba($colorBlack, $alpha: 0.5);
            height: 32px;
            width: 32px;
        }

        button:hover,
        button:focus {
            outline: none;
            box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.08);
            border-radius: 50%;
        }
    }

    .modal-body {
        .reject-reason-message{
            color: $colorRed;
            margin-left: 24px;
        }
        .body-content-header {
            display: flex;

            .client-content {
                display: flex;
                align-items: center;
                flex: 1;

                img {
                    height: 44px;
                    width: 44px;
                    padding: 5px;
                    border-radius: 50%;
                    margin-right: 8px;
                    background-color: $colorBackground;
                }

                h6 {
                    margin-bottom: 0px;
                    font-weight: 400;
                }

                p {
                    margin-bottom: 5px;
                    font-size: 0.8rem;
                    font-weight: 300;
                }

                // p{
                //     margin-bottom: 0px;
                //     font-weight: 300;
                //     font-size: 0.8rem;
                // }
            }

            .order-info {
                text-align: right;

                h6 {
                    margin-bottom: 0px;

                    .badge {
                        background-color: $colorOrange;
                    }
                }

                p {
                    margin-bottom: 0px;
                    font-size: 0.8rem;
                    font-weight: 300;
                }
            }
        }

        .body-content-body {
            margin-top: 20px;
            margin-bottom: 10px;
            h6 {
                font-weight: 400;
                text-decoration: underline;
                margin-bottom: 15px;
                color: $colorGreen;

                .icon-cart {
                    margin-right: 5px;
                    font-size: 0.9rem;
                }
            }

            .list-of-items {
                min-height: 50px;
                margin-bottom: 10px;

                .cart-item {
                    display: flex;
                    align-items: center;
                    padding: 1px 5px 1px 5px;
                    border: none;
                    color: rgba($colorBlack, $alpha: 0.5);
                    font-size: 0.8;

                    .quantity {
                        margin-right: 8px;
                    }

                    .product-name {
                        flex: 1;
                        font-weight: 300;
                    }

                    .price {
                        margin-right: 5px;
                    }

                    .currency {
                        font-size: 0.6rem;
                    }
                }
            }

            .card-prices {
                padding: 5px;
                border-top: 1px solid rgb(221, 221, 221);
                font-weight: 300;
    
                .row-price {
                    font-size: 1rem;
                    display: flex;
                    align-items: center;
    
                    .price-text {
                        margin-right: 5px;
                        flex: 3;
                        text-align: right;
                    }
    
                    .price-value {
                        flex: 1;
                        text-align: right;
                    }
                }
    
                .row-price.total-div-price {
                    display: flex;
                    justify-content: flex-end;
                    font-weight: 700;
                }
    
            }
        }

        .card-delivery-address {
            padding: 8px;
            margin-top: 30px;

            h6 {
                font-weight: 400;
                margin-bottom: 0px;
                color: $colorGreen;

                .icon {
                    margin-right: 5px;
                }
            }

            p {
                margin-left: 30px;
                margin-bottom: 0px;
                font-weight: 300;
                color: rgba($colorBlack, $alpha: 0.5);
            }
        }
        .card-delivery-biker {
            padding: 8px;
            margin-top: 30px;

            h6 {
                font-weight: 400;
                margin-bottom: 0px;
                color: $colorGreen;
                .icon {
                    margin-right: 5px;
                }
            }
            .biker-content{
                display: flex;
                margin-top: 5px;
            img{
                height: 48px;
                width: 48px;
                border-radius: 50%;
                margin-right: 5px;
            }
            h6{
                color:rgba($colorBlack, $alpha: 0.5)
            }
            p {
                margin-bottom: 0px;
                font-weight: 300;
                color: rgba($colorBlack, $alpha: 0.5);
            }
        }
        }
        .confirm-delivery-message{
            font-size: 1.4rem;
            color:$colorOrange;
            font-weight: 300;
            text-align: center;
        }
    }

    .modal-footer {
        border: none;

        button {
            background-color: $colorGreen;
            border: none;
            padding: 5px 8px 5px 8px;
            color: $colorWhite;
            border-radius: 5px;

            .icon {
                margin-right: 5px;
            }
            .spinner{
                height: 16px;
                width: 16px;
            }
        }

        button.btn-cancel {
            background-color: transparent;
            color: rgba($colorBlack, $alpha: 0.8);
            border: 1px solid rgba($colorBlack, $alpha: 0.1);
        }

        button:hover,
        button:focus {
            outline: none;
            box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.08);
        }

        .dropmenu-container{
            border: none;
            border-radius: 10px;
            padding: 10px;
            box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.1);
            .btn-drop-item{
                margin-top: 5px;
                background-color: transparent;
                color: rgba($colorBlack, $alpha: 0.8);
                border: 1px solid rgba($colorBlack, $alpha: 0.1);
            }
        }
        .dropmenu-container:focus{
            outline: none;
        }
    }
}


.container-bill{
    position: absolute;
    height: 95%;
    width: 90%;
    background-color: $colorWhite;
    padding: 30px;
    .bill-header{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 30px;
        img{
            height: 32px;
            margin-bottom: 8px;
        }
        p{
            margin-bottom: 0px; 
             font-size: 1rem;
        }
        h3{
            position: absolute;
            top: 30px;
            right: 30px;
        }
    }
    .bill-body{
        .order-content{
            display: flex;
            align-items: flex-end;
            h3{
                flex: 1;
            }
            h3.client-title{
               
            }
            p{
                margin-bottom: 0px;
                font-size: 1.2rem;
                span{
                    text-decoration: underline;
                }
            }
        }
        h6{
            margin-top: 50px;
            font-weight: 500;
        }

        .body-content-body{
            border-top: 1px solid rgb(221, 221, 221);
        }
        .list-of-items {
            min-height: 50px;
            margin-bottom: 10px;

            .cart-item {
                display: flex;
                align-items: center;
                padding: 1px 5px 1px 5px;
                border: none;
                color: $colorBlack;
                font-size: 1rem;

                .quantity {
                    margin-right: 8px;
                }

                .product-name {
                    flex: 1;
                    font-weight: 500;
                }

                .price {
                    margin-right: 5px;
                }

                .currency {
                    font-size: 1rem;
                }
            }
        }

        .card-prices {
            padding: 5px;
            border-top: 1px solid rgb(221, 221, 221);
            font-weight: 500;

            .row-price {
                font-size: 1rem;
                display: flex;
                align-items: center;

                .price-text {
                    margin-right: 5px;
                    flex: 3;
                    text-align: right;
                }

                .price-value {
                    flex: 1;
                    text-align: right;
                }
            }

            .row-price.total-div-price {
                display: flex;
                justify-content: flex-end;
                font-weight: 900;
            }

        }
    }
    .delivery-message{
        margin-top: 20px;
        text-align: right;
    }
    .bill-footer{
        text-align: center;
        font-size: 0.8rem;
        margin-top: 20px;
    }
}
