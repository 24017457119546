@import "../colors.scss";

.container-list-products{
    .container-list-products-header{
        height: 100px;
        background-color: $colorWhite;
        box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.08);
        padding: 8px;
        display: flex;
        .div-left{
            height:100%;
            flex: 1;
            h3{
                color: rgba($colorBlack, $alpha: 0.5);
                font-weight: 100;
            }
            .div-btns-actions{
                display: flex;
                button{
                    border: none;
                    border-radius: 5px;
                    font-size: 1rem;
                    margin-right: 8px;
                    height: 38px;
                    font-weight: 400;
                    border: 1px solid rgba($colorBlack, $alpha: 0.1);
                    padding-right: 10px;
                    padding-left: 10px;
                    box-shadow: 0 0 2px rgba($colorBlack, $alpha: 0.1);
                    background-color: $colorWhite;
                    color: rgba($colorBlack, $alpha: 0.5);
                }
                button:hover,
                button:focus{
                    outline: none;
                    box-shadow: 0 0 8px rgba($colorBlack, $alpha: 0.1);
                }
                .btn-create{
                        color: $colorWhite;
                        background-color: $colorGreen;
                }

            }
        }
        .div-right{
            flex: 2;
            display: flex;
            flex-direction: column;
            .div-btns{
                display: flex;
                button{
                    background-color: $colorWhite;
                    border: none;
                    color: rgba($colorBlack, $alpha: 0.5);
                    border-radius: 5px;
                    margin-right: 8px;
                    border: 1px solid rgba($colorBlack, $alpha: 0.1);
                    height: 32px;
                    padding-left: 8px;
                    padding-right: 8px;
                    box-shadow: 0 0 2px rgba($colorBlack, $alpha: 0.08);
                    .left-icon{
                        margin-right: 5px;
                        font-size: 0.8rem;
                    }
                }
                button:hover,
                button:focus{
                    outline: none;
                    box-shadow: 0 0 8px rgba($colorBlack, $alpha: 0.08);
                    color: rgba($colorBlack, $alpha: 0.8);
                }
                .btn-dropdown-toggle{
                    display: flex;
                    align-items: center;
                }
                .dropmenu-list{
                    border: none;
                    border-radius: 10px;
                    padding: 10px 20px 10px 20px;
                    overflow-y: auto;
                    min-width: 200px;
                    max-height: calc(100vh - 100px);
                    box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.1);
                    .category-item{
                        border: none;
                        display: flex;
                        align-items: center;
                        box-shadow: none;
                        border-radius: 0px;
                        font-size: 1.4rem;
                        margin-bottom: 8px;
                        font-weight: 300;
                        border-bottom: 1px solid rgba($colorBlack, $alpha: 0.1);
                        img{
                            height: 16px;
                            width: 16px;
                            margin-right: 10px;
                        }
                    }
                    .category-item.fixe{
                        color:$colorBlack;
                    }
                   .category-item:hover{
                        color:$colorGreen;
                    }
                }
                .dropmenu-list:focus{
                    border: none;
                    outline: none;
                }
                .dropmenu-list::-webkit-scrollbar-track{
                    background-color: #fff;
                }
                
                .dropmenu-list::-webkit-scrollbar{
                    width: 8px;
                    background-color: #fff;
                }
                
                .dropmenu-list::-webkit-scrollbar-thumb{ 
                    background-color: rgba(0, 0, 0, 0.1);
                    border-radius: 3px;
                }
                
            .filters-btn{
                flex: 1;
            }
        }
            .search-bar{
                margin-top: 8px;
                width: 100%;
                display: flex;
                padding-right: 8px;
                form{
                    flex: 1;
                    position: relative;
                    .search-icon{
                        position: absolute;
                        top: 12px;
                        left: 10px;
                        color: rgba($colorBlack, $alpha: 0.5);
                        z-index: 10;
                    }
                    input{
                        position: absolute;
                        border: none;
                        z-index: 11;
                        background-color: transparent;
                        box-shadow: 0 0 2px rgba($colorBlack, $alpha: 0.08);
                        height: 38px;
                        width: 100%;
                        padding-left: 32px;
                        padding-right: 16px;
                        margin-right: 8px;
                        border-radius: 5px;
                        border: 1px solid rgba($colorBlack, $alpha: 0.08);
                    }
                    input:focus{
                        outline: none;
                        box-shadow: 0 0 8px rgba($colorBlack, $alpha: 0.08);
                        border-color: $colorWhite;
                        border-radius: 5px;
                        border: 1px solid rgba($colorBlack, $alpha: 0.08);
                    }
                }
            }
        }
    }
    .container-list-products-body{
        height: calc(100vh - 152px);
        padding: 20px 8px 30px 8px;
        overflow-y: auto;
        .col-card-product{
            margin-bottom: 10px;
            .card-product{
                position: relative;
            height: 80px;
            background-color: $colorWhite;
            box-shadow: 0 0 5px rgba($colorBlack, $alpha: 0.08);
            border-radius: 5px;
            padding: 8px;
            display: flex;
            .product-available{
                position: absolute;
                height: 5px;
                width: 5px;
                border-radius: 2px;
                background-color: $colorRed
            }
            .product-available.available{
                background-color: $colorGreen;
            }
            img{
                height: 64px;
                width: 64px;
            }
            .product-info{
                margin-left: 8px;
                h6{
                    margin-bottom: 0px;
                    font-size: 0.8rem;
                }
                p{
                    margin-bottom: 0px;
                    font-size: 0.7rem;
                }
            }
            }
            .card-product:hover{
                cursor: pointer;
                box-shadow: 0 0 20px rgba($colorBlack, $alpha: 0.1);
            }
        }
    }
}

.container-new-product{
    background-color: $colorBackground;
    .new-product-header{
        display: flex;
        align-items: center;
        background-color: $colorWhite;
        padding: 10px 30px 10px 30px;
        height: 100px;
        box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.08);
        .div-titles{
            flex: 1;
            h4{
                color: rgba($colorBlack, $alpha: 0.5);
                margin-bottom: 0px;
                font-weight: 100;
                .link{
                    color: $colorGreen;
                }
            }
            .div-availability{
                display: flex;
                label{
                    margin-right: 8px;
                    font-weight: 300;
                    color: rgba($colorBlack, $alpha: 1);
                }
            }
        }
        
        .div-actions{
            position: relative;
            button{
                border: none;
                border-radius: 5px;
                font-size: 1rem;
                margin-right: 8px;
                height: 32px;
                font-weight: 400;
                border: 1px solid rgba($colorBlack, $alpha: 0.1);
                padding-right: 10px;
                padding-left: 10px;
                box-shadow: 0 0 2px rgba($colorBlack, $alpha: 0.1);
                background-color: $colorWhite;
                color: rgba($colorBlack, $alpha: 0.5);
                .spinner{
                    height: 16px;
                    width: 16px;
                }
            }
            button:hover,
            button:focus{
                outline: none;
                box-shadow: 0 0 8px rgba($colorBlack, $alpha: 0.1);
            }
            .btn-save{
                    color: $colorWhite;
                    background-color: $colorGreen;
            }
            .stock-input{
                display: flex;
                position: absolute;
                align-items: center;
                // margin-top: 10px;
                background-color: $colorBackground;
                right: 0px;
                bottom: -70px;
                padding: 10px;
                border-radius: 8px;
                // box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.08);
                label{
                    margin-right: 8px;
                    font-weight: 300;
                    margin-bottom: 0px;
                }
                input{
                    // width: 100px;
                    // height: 30px;
                    padding: 5px;
                    border: 2px solid rgba($colorBlack, $alpha: 0.05);
                    border-radius: 8px;
                }
                input:focus{
                    outline: none;
                    border: 2px solid $colorGreen;
                    box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.08);
                }
            }
            .stock-input.allowed{
                width: 96%;
                bottom: -50px;
                left:0px;  
            }
        }
    }
    .new-product-body{
        padding: 20px 30px 20px 30px;
        overflow-y: auto;
        height:calc(100vh - 152px);
        .nav{
            .link-tab{
                font-size: 1.2rem;
                font-weight: 300;
                color: rgba($colorBlack, $alpha: 0.5);
            }
            .link-tab:hover{
                cursor: pointer;
            }
            .link-tab.active{
                background-color: transparent;
                color:$colorGreen;
                border-bottom-color: $colorBackground;
                font-weight: 500;
            }
            .link-tab.active:hover{
                cursor: default;
            }
        }
        .div-availability{
            label{
                // font-size: 1.2rem;
                font-weight: 300;
                color: rgba($colorBlack, $alpha: 0.5);
                margin-bottom: 10px;
            }
        }

        
        .col-image-product{
            label{
                // font-size: 1.2rem;
                font-weight: 300;
                color: rgba($colorBlack, $alpha: 0.5);
                span{
                    margin-right: 5px;
                    color: rgba($colorGreen, $alpha: 0.5);
                }
            }
            .dropzone-image{
                width: 100%;
                height: 250px;
                border: 2px solid rgba($colorBlack, $alpha: 0.05);
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                .btn-upload{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    p{
                        font-size: 3rem;
                        margin-bottom: 0px;
                        color: rgba($colorBlack, $alpha: 0.1);
                    }
                    h6{
                        margin-bottom: 0px;
                        color: rgba($colorBlack, $alpha: 0.3);
                    }
                }
            }
            .dropzone-image:hover{
                cursor: pointer;
                box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.05);
            }
            .dropzone-image:focus,
            .dropzone-image.required{
                outline: none;
                border: 2px solid $colorGreen;
                box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.08);
            }
        }
        .div-btn-gallery{
            padding-top:20px;
            button{
                float: right;
                border-radius: 5px;
                border: 2px solid rgba($colorBlack, $alpha: 0.1);
                background-color: transparent;
                color: rgba($colorBlack, $alpha: 0.5);
            }
            button:hover,
            button:focus{
                outline: none;
                box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.08);
                color:rgba($colorBlack, $alpha: 0.8)
            }
        }
    }
}

.container-edit-product{
    .edit-product-header{
        display: flex;
        align-items: center;
        background-color: $colorWhite;
        height: 100px;
        padding: 10px 30px 10px 30px;
        box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.08);
        .div-titles{
            flex: 1;
            h4{
                color: rgba($colorBlack, $alpha: 0.5);
                margin-bottom: 5px;
                .link{
                    color: $colorGreen;
                }
                .name{
                    font-weight: 300;
                    font-size: 1.2rem;
                }
            }
            p{
                font-size: 0.8rem;
                margin-bottom: 0px;
            }
        }
        button{
            border: none;
            border-radius: 5px;
            font-size: 1rem;
            margin-right: 8px;
            height: 32px;
            font-weight: 400;
            border: 1px solid rgba($colorBlack, $alpha: 0.1);
            padding-right: 10px;
            padding-left: 10px;
            box-shadow: 0 0 2px rgba($colorBlack, $alpha: 0.1);
            background-color: $colorWhite;
            color: rgba($colorBlack, $alpha: 0.5);
            .icon{
                font-size: 0.8rem;
                margin-right: 5px;
            }
        }
        button:hover,
        button:focus{
            outline: none;
            box-shadow: 0 0 8px rgba($colorBlack, $alpha: 0.1);
        }
        .div-actions{
            position: relative;
            display: flex;
            justify-content: flex-end;
            // width: 200px;
            button{
                border: none;
                border-radius: 5px;
                font-size: 1rem;
                margin-right: 8px;
                height: 32px;
                font-weight: 400;
                border: 1px solid rgba($colorBlack, $alpha: 0.1);
                padding-right: 10px;
                padding-left: 10px;
                box-shadow: 0 0 2px rgba($colorBlack, $alpha: 0.1);
                background-color: $colorWhite;
                color: rgba($colorBlack, $alpha: 0.5);
                .spinner{
                    height: 16px;
                    width: 16px;
                }
            }
            button:hover,
            button:focus{
                outline: none;
                box-shadow: 0 0 8px rgba($colorBlack, $alpha: 0.1);
            }
            .btn-save{
                    color: $colorWhite;
                    background-color: $colorGreen;
            }
            .stock-input{
                display: flex;
                position: absolute;
                align-items: center;
                // margin-top: 10px;
                background-color: $colorBackground;
                right: 0px;
                bottom: -70px;
                padding: 10px;
                border-radius: 8px;
                // box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.08);
                label{
                    margin-right: 8px;
                    font-weight: 300;
                    margin-bottom: 0px;
                }
                input{
                    // width: 100px;
                    // height: 30px;
                    padding: 5px;
                    border: 2px solid rgba($colorBlack, $alpha: 0.05);
                    border-radius: 8px;
                }
                input:focus{
                    outline: none;
                    border: 2px solid $colorGreen;
                    box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.08);
                }
            }
            .stock-input.allowed{
                width: 96%;
                bottom: -50px;
                left:0px;  
            }
        }
    }
    .edit-product-body{
        padding: 20px 30px 20px 30px;
        overflow-y: auto;
        height:calc(100vh - 152px);
        .nav{
            .link-tab{
                color: rgba($colorBlack, $alpha: 0.5);
                font-size: 1.2rem;
                font-weight: 300;
            }
            .link-tab:hover{
                cursor: pointer;
            }
            .link-tab.active{
                background-color: transparent;
                color:$colorGreen;
                border-bottom-color: $colorBackground;
                font-weight: 500;
            }
            .link-tab.active:hover{
                cursor: default;
            }
        }
        .div-availability{
            label{
                // font-size: 1.2rem;
                font-weight: 300;
                color: rgba($colorBlack, $alpha: 0.5);
                margin-bottom: 10px;
            }
        }
        .col-image-product{
            label{
                // font-size: 1.2rem;
                font-weight: 300;
                color: rgba($colorBlack, $alpha: 0.5);
                span{
                    margin-right: 5px;
                    color: rgba($colorGreen, $alpha: 0.5);
                }
            }
            .dropzone-image{
                width: 100%;
                height: 250px;
                border: 2px solid rgba($colorBlack, $alpha: 0.05);
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                .btn-upload{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    p{
                        font-size: 3rem;
                        margin-bottom: 0px;
                        color: rgba($colorBlack, $alpha: 0.1);
                    }
                    h6{
                        margin-bottom: 0px;
                        color: rgba($colorBlack, $alpha: 0.3);
                    }
                }
            }
            .dropzone-image:hover{
                cursor: pointer;
                box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.05);
            }
            .dropzone-image:focus{
                outline: none;
                border: 2px solid $colorGreen;
                box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.08);
            }
        }
        .div-btn-gallery{
            padding-top:20px;
            button{
                float: right;
                border-radius: 5px;
                border: 2px solid rgba($colorBlack, $alpha: 0.1);
                background-color: transparent;
                color: rgba($colorBlack, $alpha: 0.5);
            }
            button:hover,
            button:focus{
                outline: none;
                box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.08);
                color:rgba($colorBlack, $alpha: 0.8)
            }
        }
    }
}

.modal-delete-product{
    .modal-delete-product-body{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        p{
            font-size: 1.2rem;
            margin-top: 20px;
            margin-bottom: 0px;
        }
        p.info{
            margin-top: 0px;
            font-size: 1rem;
            color:$colorRed;
            margin-bottom: 0px
        }
        button{
            margin: 8px;
            border: none;
                border-radius: 5px;
                font-size: 1rem;
                margin-right: 8px;
                height: 32px;
                font-weight: 400;
                border: 1px solid rgba($colorBlack, $alpha: 0.1);
                padding-right: 10px;
                padding-left: 10px;
                box-shadow: 0 0 2px rgba($colorBlack, $alpha: 0.1);
                background-color: $colorWhite;
                color: rgba($colorBlack, $alpha: 0.5);
                .spinner{
                    height: 16px;
                    width: 16px;
                }
            
        }
        button.btn-delete{
            color: $colorWhite;
            background-color: $colorGreen;
        }
        button:hover,
        button:focus{
            outline: none;
            box-shadow: 0 0 8px rgba($colorBlack, $alpha: 0.1);
        }

    }
}

.card-image-from-gallery{
    margin-bottom: 30px;
    padding:5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px rgba($colorBlack, $alpha: 0.05);
    border-radius: 10px;
    img{
        height: 96px;
        width: 96px;
    }
}
.card-image-from-gallery:hover{
    box-shadow: 0 0 20px rgba($colorBlack, $alpha: 0.08);
    cursor: pointer;
}

.div-current-seller{
    label{
        font-weight: 100;
        color: $colorGreen;
    }
    .seller-info{
         display: flex;
         flex-direction: column;
         align-items: center;
         
         img{
             height: 96px;
             width: 96px;
             margin-right: 8px;
         }
         h6{
             margin-top: 10px;
             font-size: 2rem;
             font-weight: 300;
         }
    }
}
.container-list-sellers{
    margin-top: 20px;
label{
    font-weight: 100;
    color: $colorGreen;
}

.search-bar{
    margin-top: 8px;
    width: 100%;
    display: flex;
    padding-right: 8px;
    form{
        flex: 1;
        position: relative;
        .search-icon{
            position: absolute;
            top: 12px;
            left: 10px;
            color: rgba($colorBlack, $alpha: 0.5);
            z-index: 10;
        }
        input{
            // position: absolute;
            border: none;
            // z-index: 11;
            background-color: transparent;
            box-shadow: 0 0 2px rgba($colorBlack, $alpha: 0.08);
            height: 38px;
            width: 98%;
            padding-left: 32px;
            padding-right: 16px;
            // margin-right: 8px;
            margin-bottom: 20px;
            border-radius: 5px;
            // border: 1px solid rgba($colorBlack, $alpha: 0.08);
        }
        input::placeholder{
            font-size: 0.7rem;
        }
        input:focus{
            outline: none;
            box-shadow: 0 0 8px rgba($colorBlack, $alpha: 0.08);
            border-color: $colorWhite;
            border-radius: 5px;
            border: 1px solid rgba($colorBlack, $alpha: 0.08);
        }
    }
}
.seller-item{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 8px;
    box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.03);
    border-radius: 8px;
    img{
        max-width: 100%;
       height: 72px;
    }
    h6{
        font-weight: 300;
        margin-top: 5px;
        margin-bottom: 0px;
        text-align: center;
    }
}
.seller-item:hover{
    cursor: pointer;
   background-color: $colorWhite;
}
.empty-sellers-list{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    color: rgba($colorBlack, $alpha: 0.3);
}
}