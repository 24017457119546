@import "../colors.scss";

.sidebar {
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100%;
    width: 250px;
    background-color: $colorWhite;
    top: 0px;
    left: 0px;
    z-index: 101;
    transition: transform 0.6s ease-out;
    transform: translateX(-100%);
    border-right: 1px solid rgba($colorBlack, $alpha: 0.08);

    .sidebar-header {
        height: 52px;
        margin-bottom: 0px;
        display: flex;
        align-items: center;
        padding-left: 8px;
        padding-right: 8px;
        border-bottom: 1px solid rgba($colorBlack, $alpha: 0.08);
        color: inherit;
        text-decoration: none;
        h1 {
            flex: 1;
            font-size: 1.5rem;
            font-weight: 900;

            span {
                color: $colorGreen;
            }
        }

        button {
            background-color: transparent;
            border: none;
            border-radius: 5px;
            box-shadow: 0 0 2px rgba($colorBlack, $alpha: 0.08);
            color: rgba($colorBlack, $alpha: 0.5);
        }

        button:focus {
            outline: none;
        }
    }

    .sidebar-content {
        height: calc(100vh - 52px);
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        .sidebar-body {
            flex: 1;
            // box-shadow: 10px 0 10px -10px rgba(0, 0, 0, 0.08);
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 20px;

            .sidebar-menu-item {
                border: none;
                font-size: 1.2rem;
                font-weight: 300;
                color: rgba($colorBlack, $alpha: 0.7);
                padding: 8px 8px 8px 8px;
                margin-bottom: 5px;
                border-radius: 5px;
                background-color: transparent;

                .icon {
                    margin-right: 15px;
                    color: $colorGreen;
                    width: 24px;
                }
            }

            .sidebar-menu-item:hover {
                cursor: pointer;
                background-color: rgba($colorGreen, $alpha: 0.05);
            }

            .sidebar-menu-item.active {
                background-color: rgba($colorGreen, $alpha: 0.2);
                font-weight: 400;
                // color: $colorGreen;
            }
        }

        .sidebar-footer {
            padding: 0px 8px 5px 8px;

            p {
                font-size: 0.6rem;
                margin-bottom: 0px;
                color: rgba(0, 0, 0, 0.5);
            }
        }
    }

    .sidebar-content::-webkit-scrollbar-track {
        background-color: #fff;
    }

    .sidebar-content::-webkit-scrollbar {
        width: 8px;
        background-color: #fff;
    }

    .sidebar-content::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 3px;
    }
}

.sidebar.hide {
    transform: translateX(-100%);
}

@media (min-width: 992px) {
    .sidebar {
        transform: translateX(0);

        .sidebar-header {
            button {
                display: none;
            }
        }
    }
}

@media (max-width:991px) {
    .sidebar.hide {
        transform: translateX(0);
    }
}
