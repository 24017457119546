@import "../baseColor";

header{
    position: fixed;
    z-index: 100;
    height: 48px;
    width: calc(100% - 250px);
    display: flex;
    align-items: center;
    .blank-space{
        flex: 1;
    }
    .btn-busy{
        border: 2px solid $colorOrange;
        color: $colorOrange;
        outline: none;
    }
    .btn-busy:hover{
        background-color: rgba(255, 153, 0, 0.788);
        border: solid 2px $colorOrange;
        color: $colorBlack;
    }
    
    .btn-deconnexion:hover{
        color: rgba(0, 0, 0, 0.5);
        cursor: pointer;
    }
}

@media (min-width: 992px){
    header{
        left: 250px;
    }
    
}

@media (max-width:991.98px){
    header{
        left: 0px;
        width: 100%;
    }
}

