@import "../colors.scss";

header {
    position: fixed;
    background-color: $colorWhite;
    top: 0px;
    z-index: 100;
    height: 52px;
    width: calc(100% - 250px);
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba($colorBlack, $alpha: 0.08);

    .btn-hamburger {
        background-color: $colorWhite;
        border: none;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        height: 34px;
        width: 34px;
        color: rgba($colorBlack, $alpha: 0.3);
        margin-right: 8px;
    }
    h1{
        font-size: 1.5rem;
        font-weight: 900;
        span{
            color:$colorGreen;
        }
    }

    .btn-hamburger:hover {
        box-shadow: 0 0 8px rgba($colorBlack, $alpha: 0.1);
    }

    .empty-space {
        flex: 1;
    }

    .div-left {
        display: flex;
        align-items: center;

        button {
            height: 34px;
            width: 34px;
            border: none;
            background-color: $colorWhite;
            color: rgba($colorBlack, $alpha: 0.3);
            border-radius: 5px;
            margin-left: 5px;
            margin-right: 5px;
            box-shadow: 0 0 2px rgba($colorBlack, $alpha: 0.1);
        }

        button:hover,
        button:focus {
            outline: none;
            color: rgba($colorBlack, $alpha: 0.8);
            box-shadow: 0 0 8px rgba($colorBlack, $alpha: 0.1);

        }

        .img-avatar {
            display: flex;
            box-shadow: 0 0 2px rgba($colorBlack, $alpha: 0.1);
            height: 40px;
            align-items: center;
            margin-left: 8px;
            border-radius: 20px;
            padding-left: 5px;
            padding-right: 10px;

            .img {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 32px;
                width: 32px;
                background-color:$colorBackground;
                border-radius: 50%;
                border:2px solid $colorBackground;
                margin-right: 5px;
                color: rgba($colorBlack, $alpha: 0.2);
            }

            p {
                margin-bottom: 0px;
                font-size: 14px;
                font-weight: 400;
                color: rgba($colorBlack, $alpha: 0.8);
            }
        }

        .img-avatar:hover {
            cursor: pointer;
            box-shadow: 0 0 8px rgba($colorBlack, $alpha: 0.1);

            p {
                color: rgba($colorBlack, $alpha: 0.8);
            }
        }
    }

    .btn-hamburger:hover,
    .btn-hamburger:focus {
        outline: none;
    }

    .blank-space {
        flex: 1;
    }

    .btn-busy {
        border: 2px solid $colorOrange;
        color: $colorOrange;
        outline: none;
    }

    .btn-busy:hover {
        background-color: rgba(255, 153, 0, 0.788);
        border: solid 2px $colorOrange;
        color: $colorBlack;
    }
    
    
}
.btn-deconnect{
    width: 90%;
    margin: 8px;
    border: none;
    background-color: $colorWhite;
    color: rgba($colorBlack, $alpha: 0.3);
    border-radius: 5px;
    padding:4px 15px 4px 15px;
    box-shadow: 0 0 2px rgba($colorBlack, $alpha: 0.1);
}
.btn-deconnect:hover,
.btn-deconnect:focus {
        box-shadow: 0 0 10px rgba($colorBlack, $alpha: 0.08);
        outline: none;
    }

@media (min-width: 992px) {
    header {
        left: 250px;
    }
    header.hide{
        left: 0px;
        width: 100%;
        // z-index: 102;
    }

}

@media (max-width:991.98px) {
    header {
        left: 0px;
        width: 100%;
    }
}
